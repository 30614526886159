$(document).on("ready", function() {
  // initialization of header
  $.HSCore.components.HSHeader.init($("#js-header"));
  $.HSCore.helpers.HSHamburgers.init(".hamburger");

  // initialization of HSMegaMenu component
  $(".js-mega-menu").HSMegaMenu({
    event: "hover",
    pageContainer: $(".container"),
    breakpoint: 991
  });

  // initialization of HSDropdown component
  $.HSCore.components.HSDropdown.init($("[data-dropdown-target]"), {
    afterOpen: function() {
      $(this)
        .find('input[type="search"]')
        .focus();
    }
  });

  // initialization of video on background
  $.HSCore.helpers.HSBgVideo.init(".js-bg-video");

  // initialization of forms
  $.HSCore.components.HSMaskedInput.init("[data-mask]");

  // initialization of popups
  $.HSCore.components.HSPopup.init(".js-fancybox");

  // initialization of carousel
  $.HSCore.components.HSCarousel.init(".js-carousel");

  // initialization of go to
  $.HSCore.components.HSGoTo.init(".js-go-to");

    const addSubscriber = () => {
        const axios = require('axios');
        let email = document.getElementById("email").value;
        axios.post('newsLetterController/store', {
            email: email
        })
            .then(function (response) {
                console.log(response);

            })
    }
});


